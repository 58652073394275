var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-3"},[_c('v-row',[_c('div',{staticClass:"mt-3"},[_c('I18nFormattedMessage',{attrs:{"id":"PersonalNotification.explain1","defaultMessage":"通知を登録した人のみが変更/削除可能です。"}}),_c('br'),_c('I18nFormattedMessage',{attrs:{"id":"PersonalNotification.explain2","defaultMessage":"ご自身で登録してない通知を変更/削除したい場合は通知登録者にご依頼ください。"}})],1)]),_c('v-row',{staticClass:"mt-10"},[(!_vm.state.loading)?_c('v-data-table',{staticClass:"custom-item-class",attrs:{"headers":_vm.tableHeaders,"items":_vm.state.tableItems,"items-per-page":-1,"sort-by":"notificationDate"},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"item.message",fn:function(ref){
var item = ref.item;
return [(item.message)?_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"},domProps:{"textContent":_vm._s(item.message.replace(/<[^>]*>/g, ' '))}}):_c('Loading',{style:(24)})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.isAuther)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onRowDeleteBtnClick(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.mtgStructureName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mtgStructureName ? item.mtgStructureName : "-")+" ")]}},{key:"item.meetingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.meetingDate ? item.meetingDate : "-")+" ")]}}],null,true)}):_c('Loading')],1),_c('NotificationEditDialog',{attrs:{"tableItem":_vm.state.activeItem,"visible":_vm.state.isOpenEditDialog},on:{"cancel":_vm.onCancel,"ok":_vm.notificationCreate}}),_c('ConfirmDialog',{attrs:{"customName":"delete-notification","visible":!!_vm.state.deleteItem},on:{"cancel":function($event){_vm.state.deleteItem = null},"ok":_vm.onDeleteDialogOK},scopedSlots:_vm._u([{key:"dialogBody",fn:function(){return [_c('I18nFormattedMessage',{attrs:{"id":"PersonalNotification.deleteDialog.subtitle","default-message":"通知を削除しますか？"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }