










































































import {
  defineComponent,
  computed,
  reactive,
  onMounted,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TableHeader from "@/utilities/TableHeader"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import NotificationEditDialog from "./NotificationEditDialog.vue"
import Loading from "@/components/Loading.vue"
import { notificationContainer } from "@/containers/NotificationContainer"
import { getMeetingDeepLinkUrl } from "@/utilities/URIHelper"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import Notification from "@/models/Notification"
import { Project } from "@/models/Project"
import TeamsContext from "@/models/TeamsContext"
import { MeetingStructure } from "@/models/Meeting"
import User from "@/models/User"

export interface TableItem {
  id: string
  groupId: string
  siteId: string
  meetingId: string
  fileUrl: string
  notificationDate: string
  message: string | null
  registerer: string | null
  teamId: string
  userIds: Array<string>
  driveItems: Array<string>
  autherId: string
  isAuther: boolean
  teamName: string
  channelId: string
  channelName: string
  mtgStructureName: string
  meetingDate: string
  meetingUrl: string
  isPrivate: boolean
}

interface State {
  isOpenEditDialog: boolean
  isOpenDeleteDialog: boolean
  deleteItem: TableItem | null
  activeItem: TableItem
  tableItems: Array<TableItem>
  loading: boolean
}

export default defineComponent({
  components: {
    I18nFormattedMessage,
    ConfirmDialog,
    NotificationEditDialog,
    Loading,
  },
  setup() {
    const state = reactive<State>({
      isOpenEditDialog: false,
      isOpenDeleteDialog: false,
      deleteItem: null,
      activeItem: {} as TableItem,
      tableItems: [],
      loading: true,
    })
    const {
      getContext,
      getUser,
      getMyTeams,
    } = teamsContextContainer.useContainer()
    const {
      getMyNotification,
      getNotificationContents,
      getNotificationItems,
      deleteNotification,
    } = notificationContainer.useContainer()
    const { getMyProjectsAsync } = projectsContainer.useContainer()
    const tableHeaders = computed<Array<TableHeader>>(() => [
      { text: "通知日程", value: "notificationDate" },
      { text: "通知内容", value: "message", width: 100 },
      { text: "通知登録者", value: "registerer" },
      { text: "チーム", value: "teamName" },
      { text: "チャネル", value: "channelName" },
      { text: "会議体", value: "mtgStructureName" },
      { text: "開催日", value: "meetingDate" },
      { text: "", value: "action" },
    ])

    const converToTableItem = (
      projects: Array<{
        project: Project
        meetings: Array<MeetingStructure>
      }>,
      context: TeamsContext,
      notification: Notification
    ) => {
      const targetProjects = projects.find(p =>
        p.meetings.some(m =>
          m.meetings.some(m => m.id === notification.meetingId)
        )
      )
      if (!targetProjects) return {} as TableItem
      const meetingStructure = targetProjects?.meetings.find(m =>
        m.meetings.find(mi => mi.id === notification.meetingId)
      )
      const meeting = meetingStructure?.meetings.find(
        m => m.id === notification.meetingId
      )
      return {
        id: notification.id,
        meetingId: notification.meetingId,
        fileUrl: notification.bodyImageUrl,
        groupId: targetProjects.project.groupId,
        siteId: targetProjects.project.siteId,
        notificationDate: notification.notificationDate.toDateStringSlashFull(),
        teamName: "",
        channelId: targetProjects.project.channelId,
        channelName: "",
        message: null,
        registerer: "",
        driveItems: [],
        teamId: notification.teamId,
        userIds: notification.userIds,
        autherId: notification.autherId,
        isAuther: notification.autherId === context.userObjectId,
        mtgStructureName: meetingStructure?.name,
        meetingDate: meeting?.startTime.toDateStringSlash(),
        meetingUrl:
          !meetingStructure || !meeting
            ? null
            : getMeetingDeepLinkUrl(
                targetProjects.project,
                meetingStructure,
                meeting
              ),
        isPrivate: notification.isFromPrivate,
      } as TableItem
    }

    onMounted(async () => {
      const projects = await getMyProjectsAsync()
      const context = await getContext()
      const notifications = await getMyNotification()
      state.tableItems = notifications.map(notification => {
        return converToTableItem(projects, context, notification)
      })
      state.tableItems = state.tableItems.filter(t => t.id !== undefined)
      state.loading = false
      const teams = await getMyTeams(state.tableItems.map(n => n.groupId))
      const additionals = await Promise.all(
        state.tableItems.map(async (tabItem: TableItem) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const tasks: Array<Promise<any>> = []
          tasks.push(
            (async (currentItem: TableItem) => {
              const driveItems = await getNotificationItems(
                currentItem.groupId,
                currentItem.siteId,
                currentItem.fileUrl
              )
              let message = ""
              if (driveItems["html"]) {
                const drive = driveItems["html"]
                message = await getNotificationContents(drive)
              }
              return {
                message,
                driveItems: [driveItems["html"]?.id, driveItems["jpg"]?.id],
              }
            })(tabItem)
          )
          tasks.push(getUser(tabItem.autherId))
          const team = teams.find(t => t.team.id === tabItem.groupId)
          const [message, user] = await Promise.all(tasks)
          return {
            id: tabItem.id,
            message: message.message,
            driveItems: message.driveItems,
            user: user ?? new User(),
            teamName: team?.team.displayName,
            channelName: team?.channels.find(c => c.id === tabItem.channelId)
              ?.displayName,
          }
        })
      )
      if (additionals) {
        for (const additional of additionals) {
          if (additional.message) {
            const message = additional.message
            state.tableItems = state.tableItems.map(t =>
              additional.id === t.id
                ? Object.assign(t, {
                    message,
                    registerer: additional.user.displayName,
                    driveItems: additional.driveItems,
                    teamName: additional.teamName,
                    channelName: additional.channelName,
                  })
                : t
            )
          }
        }
      }
    })

    const onRowDeleteBtnClick = async (item: TableItem) => {
      state.deleteItem = item
    }
    const onCancel = () => {
      state.isOpenEditDialog = false
      state.activeItem = {} as TableItem
    }

    const onRowClicked = (item: TableItem) => {
      if (!item.message) return
      state.isOpenEditDialog = true
      state.activeItem = item
    }

    const onDeleteDialogOK = async () => {
      if (state.deleteItem)
        await deleteNotification(
          state.deleteItem.siteId,
          state.deleteItem.id,
          state.deleteItem.driveItems
        )
      state.tableItems = state.tableItems?.filter(
        d => d.id !== state.deleteItem?.id
      )
      state.deleteItem = null
    }

    const notificationCreate = async ({
      item,
      newItemId,
      driveItems,
    }: {
      item: TableItem
      newItemId: string
      driveItems: (string | undefined)[]
    }) => {
      state.tableItems = state.tableItems?.map(d =>
        d.id === item?.id
          ? Object.assign(item, {
              id: newItemId,
              driveItems: driveItems.filter(f => !!f),
            })
          : d
      )
      state.isOpenEditDialog = false
    }

    return {
      state,
      tableHeaders,
      onCancel,
      onRowDeleteBtnClick,
      onRowClicked,
      onDeleteDialogOK,
      notificationCreate,
    }
  },
})
